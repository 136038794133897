// @flow
import React from 'react';

import { Methods, Heading, TwoColumns, InView } from 'components';
import { convertHTMLtoStyled } from '../../../utils/convertHTMLtoStyled';
import { Section, Container } from '../../../styles/global';

type Props = {
  title: string,
  bgColor: string,
  columns: array[],
  copy: string,
  pageUrl: string,
};

const TextModule = ({ title, bgColor, columns, copy, pageUrl }: Props) => {
  const hasColumns = columns;
  const isMethods = hasColumns && columns.length === 3;

  return (
    <Section bgColor={bgColor}>
      <Container>
        {title && (
          <InView fadeSlide>
            <Heading.H2>{title}</Heading.H2>
          </InView>
        )}
        {copy && convertHTMLtoStyled(copy, true)}
        {hasColumns && !isMethods && (
          <TwoColumns pageUrl={pageUrl} columns={columns} />
        )}
        {isMethods && <Methods columns={columns} />}
      </Container>
    </Section>
  );
};

export default TextModule;
