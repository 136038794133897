// @flow
import React, { useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';

import { HeroWrapper, HeroTitle, EndFrame, PlayButton } from './index.css';

import { Container } from '../../../styles/global';
import { renderUrl } from '../../../utils/renderCloudinaryUrl';

type Props = {
  title: string,
  bgImage: string,
  vimeoId: string,
  vimeoAutoplay: boolean,
  vimeoLoop: boolean,
};

const Hero = ({
  title,
  bgImage,
  vimeoId = '',
  vimeoAutoplay = false,
  vimeoLoop = false,
}: Props) => {
  const [paused, setPaused] = useState(false);

  const handlePlayerPlay = () => setPaused(false);

  const handlePlayerPause = () => setPaused(true);

  return (
    <HeroWrapper bgImage={renderUrl(bgImage)} video={vimeoId}>
      {vimeoId ? (
        <>
          <Vimeo
            muted
            video={vimeoId}
            autoplay={vimeoAutoplay}
            loop={vimeoLoop}
            controls={false}
            showByline={false}
            showTitle={false}
            showPortrait={false}
            paused={paused}
            onPlay={handlePlayerPlay}
            onPause={handlePlayerPause}
          />
          <EndFrame show={paused}>
            <PlayButton onClick={handlePlayerPlay}>Play</PlayButton>
          </EndFrame>
        </>
      ) : (
        <Container>
          <HeroTitle>{title}</HeroTitle>
        </Container>
      )}
    </HeroWrapper>
  );
};

export default Hero;
