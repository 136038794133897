import styled from 'styled-components';

import { fadeIn } from '../../../styles/global';
import { media } from '../../../styles/responsive';

export const Main = styled.main`
  opacity: 0;
  animation: ${fadeIn} 0.3s ease forwards;
  padding-top: 66px;

  ${media.md`
    padding-top: 0;
  `}
`;
