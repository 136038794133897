import { useState, useEffect } from 'react';

const OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 0.05,
};

export const useIntersectionObserver = ref => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && entry.intersectionRatio <= 1) {
        setIntersecting(true);
        observer.unobserve(ref.current);
      }
    }, OPTIONS);
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, []);

  return isIntersecting;
};
