// @flow
import React from 'react';

import { Blockquote } from 'components';

import { useTestimonialData } from '../../../hooks/useTestimonialData';

type Props = {
  id: string,
};

const TestimonialList = ({ id }: Props) => {
  const testimonials = useTestimonialData();
  let data = testimonials;

  if (id !== 'undefined') {
    data = testimonials.filter(i => i.id === id);
  }

  return data
    .sort((a, b) => a.quote.length - b.quote.length)
    .map(testimonial => (
      <Blockquote
        key={testimonial.id}
        listItem={data.length > 1}
        quote={testimonial.quote}
        author={testimonial.author}
      />
    ));
};

export default TestimonialList;
