import styled from 'styled-components';

import * as Color from '../../../styles/colors';

/**
 * Returns ar percentage.
 * @param {string} ar Type of aspect ratio, e.g 4:3, 16:9 etc.. (w:h)
 */
const calculateAR = (ar = '16:9') => {
  const [w, h] = ar.split(':');
  return h / (w / 100);
};

export default styled.div`
  position: relative;
  padding-bottom: ${props => calculateAR(props.ar)}%;
  background-color: ${Color.PAPER};

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    max-width: 100%;
    top: 0;
    left: 0;
  }
`;
