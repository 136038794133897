import styled, { css } from 'styled-components';

import { Anchor } from 'components';
import { media } from '../../../styles/responsive';

import * as Color from '../../../styles/colors';

export default styled(Anchor)`
  display: block;
  text-align: center;
  padding: 14px 16px;
  border: 2px solid ${Color.BLUE_WHALE};
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: transparent;
  font-size: 14px;

  ${props => props.theme === 'light' && css`
    border-color: rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.5);
  `};

  ${props => props.theme === 'dark' && css`
    border-color: ${Color.WHITE};
    color: ${Color.WHITE};
  `};

  &:after {
    display: none;
  }

  &:hover,
  &:focus {
    background-color: ${Color.BLUE_WHALE};
    color: ${Color.WHITE} !important;
  }

  ${media.md`
    display: inline-block;
  `};
`;
