import { useStaticQuery, graphql } from 'gatsby';

export const useTimelineData = () => {
  const { allMarkdownRemark: { nodes } = [] } = useStaticQuery(graphql`
    query Timeline {
      allMarkdownRemark(filter: {
        frontmatter: {
          eventDate: {
            regex: "/[0-9]/"
          }
        }
      }, 
      sort: {
        fields: frontmatter___eventDate, 
        order: ASC
      }) {
        nodes {
          frontmatter {
            eventDate
            eventDescription
            eventHeader
          }
        }
      }
    }  
  `);

  return nodes.map(node => ({
    date: node.frontmatter.eventDate,
    header: node.frontmatter.eventHeader,
    description: node.frontmatter.eventDescription,
  }));
};
