import styled, { css } from 'styled-components';

import { HEADER_FONT } from '../../../styles/fonts';

const BASE_HEADER_STYLE = css`
  ${HEADER_FONT};
  font-weight: 100;
  font-style: italic;
`;

const H1 = styled.h1`
  ${BASE_HEADER_STYLE};
  font-size: 50px;
  line-height: 54px;
  margin: 0;
`;

const H2 = styled.h2`
  ${BASE_HEADER_STYLE};
  font-size: 40px;
  line-height: 46px;
  margin-bottom: 0;
`;

const H3 = styled.h3`
  ${BASE_HEADER_STYLE};
  font-size: 30px;
  margin: 20px 0;
`;

const H4 = styled.h4``;

export default {
  H1,
  H2,
  H3,
  H4,
};
