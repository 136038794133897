import { useStaticQuery, graphql } from 'gatsby';

export const useSocialData = () => {
  const { allFrontmatterMarkdownFile: { nodes } = [] } = useStaticQuery(graphql`
    query SocialQuery {
      allFrontmatterMarkdownFile(
        filter: { frontmatterField: { regex: "/Url/" } }
      ) {
        nodes {
          frontmatterValue
          frontmatterField
        }
      }
    }
  `);

  return nodes.map(node => ({
    url: node.frontmatterValue,
    type: node.frontmatterField.replace('Url', ''),
  }));
};
