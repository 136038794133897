import styled from 'styled-components';

export default styled.p`
  line-height: ${props => props.lineHeight || '20px'};
  margin: ${props => props.margin || '0 0 14px 0'};
  font-size: ${props => props.fontSize || 'inherit'};

  &:last-child {
    margin-bottom: 0;
  }
`;
