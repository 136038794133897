import styled from 'styled-components';

import { Quote, Wrapper } from '../../molecules/Blockquote/index.css';

import * as SocialLinks from '../../molecules/SocialLinks/index.css';
import * as Color from '../../../styles/colors';
import { media } from '../../../styles/responsive';

export const Footer = styled.footer`
  background-color: ${Color.TEAL_BLUE};
  text-align: center;
  padding: 30px 20px;
  color: ${Color.WHITE};

  ${SocialLinks.Wrapper} {
    border: 0;
    padding: 0;
    margin: 0;
    max-width: none;
  }

  ${Wrapper} {
    transition: none;
    ${media.md`
      margin: 20px auto;
      max-width: 456px;
    `};
    
    margin: 20px 0;
    color: rgba(255, 255, 255, .4);

    ${Quote}:after {
      bottom: 14px;
    }

    &:before {
      top: -14px;
    }

    &:before,
    ${Quote}:after {
      color: rgba(255, 255, 255, .2);
      font-size: 60px;
    }
  }
`;
