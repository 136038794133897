import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import * as Color from '../../../styles/colors';

const AnchorStyles = css`
  color: ${Color.BLUE_WHALE};
  text-decoration: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: -4px;
    left: 0;
    background-color: ${Color.BLUE_WHALE};
  }

  &:hover {
    color: #ccc;
  }
`;

export const Anchor = styled.a`
  ${AnchorStyles};
`;

export const RelativeAnchor = styled(Link)`
  ${AnchorStyles};
`;
