// @flow
import React, { useState, createRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Paragraph } from 'components';

import {
  TheForm,
  Fieldset,
  Label,
  Input,
  Textarea,
  SubmitButton,
  FormContainer,
  FormLoader,
  FormSuccess,
} from './index.css';

import Loader from '../../../../static/img/loader.inline.svg';

type Props = {
  subject: string,
};

const ContactForm = ({ subject }: Props) => {
  const [formState, setFormState] = useState('form');
  const [values, setValues] = useState({
    name: '',
    email: '',
    message: '',
    recaptcha: '',
  });
  const recaptchaRef = createRef();

  const onChange = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    setValues({ ...values, recaptcha: recaptchaValue });
  };

  const resetForm = () => {
    setValues({
      name: '',
      email: '',
      message: '',
      recaptcha: '',
    });

    const timer = setTimeout(() => {
      setFormState('form');
      clearTimeout(timer);
    }, 5000);
  };

  const handleSubmit = e => {
    const data = { ...values, subject };
    e.preventDefault();

    if (values.recaptcha === '') {
      setFormState('noCaptcha');
      return;
    }

    setFormState('loading');

    fetch('/send-email', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(() => {
      setFormState('success');
      resetForm();
    });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <FormContainer id="form">
      <FormLoader show={formState === 'loading'}>
        <Paragraph fontSize="22px">Sending</Paragraph>
        {formState === 'loading' && <Loader />}
      </FormLoader>
      <FormSuccess show={formState === 'success'}>
        <Paragraph fontSize="22px">Thank you for your message!</Paragraph>
        <Paragraph fontSize="14px">We will be in touch.</Paragraph>
      </FormSuccess>
      <TheForm onSubmit={handleSubmit} show={formState === 'form' || formState === 'noCaptcha'} action="#">
        <Fieldset>
          <Input
            name="name"
            type="text"
            id="name"
            required
            placeholder="Name"
            autoComplete="off"
            onChange={handleInputChange}
            value={values.name}
          />
          <Label htmlFor="name">Name</Label>
        </Fieldset>

        <Fieldset>
          <Input
            name="email"
            type="email"
            id="email"
            autoComplete="off"
            required
            placeholder="Email"
            onChange={handleInputChange}
            value={values.email}
          />
          <Label htmlFor="email">Email</Label>
        </Fieldset>

        <Fieldset>
          <Textarea
            as="textarea"
            name="message"
            id="message"
            required
            placeholder="Message"
            value={values.message}
            onChange={handleInputChange}
          />
          <Label htmlFor="message">Message</Label>
        </Fieldset>

        <Fieldset>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LeKrNoUAAAAAIaRySZ0K3JT69nXptUV8ecK4NEs"
            onChange={onChange}
          />
          {formState === 'noCaptcha' && values.recaptcha === '' ? 'Please verify you are not a robot' : ''}
          <br /><br />
        </Fieldset>

        <SubmitButton as="button" type="submit">
          Send
        </SubmitButton>
      </TheForm>
    </FormContainer>
  );
};

export default ContactForm;
