import styled, { css } from 'styled-components';

import { Paragraph, Button } from 'components';

import { media } from '../../../styles/responsive';
import { ButtonDark, ButtonLight } from '../../../styles/global';
import { WHITE } from '../../../styles/colors';

export const Callout = styled.div`
  padding: 30px 20px 40px;
  text-align: center;
  background-color: ${props => props.bgColor || 'transparent'};
  ${props => props.theme === 'dark' && css`
    color: ${WHITE};

    ${Button} {
      ${ButtonDark};
    }
  `};


  ${props => props.theme === 'light' && css`
    ${Button} {
      ${ButtonLight};
    }
  `};

  ${Paragraph} {
    font-size: 22px;
    line-height: 30px;
    color: inherit;

    ${media.md`margin:0`};
  }
`;

export const Container = styled.div`
  ${media.md`
    display: flex;
    text-align: left;
    justify-content: center;
    align-items: center;

    ${Button} {
      margin-left: 10px;
    }
  `};
`;
