import styled from 'styled-components';

import { Button } from 'components';

import * as Color from '../../../styles/colors';
import { BODY_FONT } from '../../../styles/fonts';
import { media } from '../../../styles/responsive';

export const TheForm = styled.form`
  display: ${props => (props.show ? 'block' : 'none')};
`;

export const Fieldset = styled.fieldset`
  border: 0;
  margin: 10px 0 0 0;
  padding: 0;
`;

export const Label = styled.label`
  display: block;
  font-size: 16px;
  transform: translate(9px, -29px);
  opacity: 0;
  transform-origin: 0 0;
  cursor: text;
  transition: opacity .2s ease-in-out,
              transform .2s ease-in-out;
`;

export const Input = styled.input`
  ${BODY_FONT};
  width: 100%;
  border: 2px solid ${Color.TEAL_BLUE};
  border-radius: 4px;
  font-size: 16px;
  padding: 8px;
  resize: none;

  &:not(:placeholder-shown) + ${Label} {
    opacity: 1;
    transform: translate(0, -60px) scale(0.75);
  }
`;

export const Textarea = styled(Input)`
  height: 150px;
  
  & + ${Label} {
    transform: translate(9px, -162px);
  }

  &:not(:placeholder-shown) + ${Label} {
    transform: translate(0, -172px) scale(0.75);
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

export const FormContainer = styled.div`
  margin-top: 20px;
  position: relative;
  min-height: 200px;
  scroll-margin-top: 6rem;

  ${media.lg`
    margin-right: 100px;
  `};
`;

export const FormLoader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: ${props => (props.show ? 'flex' : 'none')};
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 15%;
  transition: opacity .6s .3s ease-in-out;
  opacity: ${props => (props.show ? 1 : 0)};

  svg {
    width: 60px;
  }
`;

export const FormSuccess = styled(FormLoader)``;
