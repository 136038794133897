import { useStaticQuery, graphql } from 'gatsby';

export const useAboutData = () => {
  const {
    markdownRemark: { frontmatter },
  } = useStaticQuery(graphql`
    query AboutData {
      markdownRemark(frontmatter: { blurb: { regex: "/[A-z]/" } }) {
        frontmatter {
          blurb
          copy
          image
        }
      }
    }
  `);

  return frontmatter;
};
