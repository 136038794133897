import React from 'react';

import { PrimaryNavigation } from 'components';

import { Header, Logo, LogoLink } from './index.css';

import logoSrc from '../../../../static/img/logo.svg';

const HeaderComponent = () => (
  <Header>
    <LogoLink href="/">
      <Logo alt="Giraldo School of Piano" src={logoSrc} />
    </LogoLink>
    <PrimaryNavigation />
  </Header>
);

export default HeaderComponent;
