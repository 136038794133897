import React from 'react';

import { Button, Paragraph, Blockquote, SocialLinks } from 'components';

import { Footer } from './index.css';

const FooterComponent = () => (
  <Footer>
    <Button href="/contact#form" theme="dark">
      <span role="img" aria-label="img">
        🎹
      </span>
      Get started today!
    </Button>

    <Blockquote
      quote="I have come to believe that a great teacher is a great artist and that there are as few as there are any other great artists. Teaching might even be the greatest of the arts since the medium is the human mind and spirit."
      author="John Steinbeck"
      authorFontSize="12px"
      quoteFontSize="14px"
      quoteLineHeight="16px"
    />

    <Paragraph fontSize="12px">
      © 2017 - {new Date().getFullYear()} Giraldo School of Piano.
      <br />
      All Rights Reserved.
    </Paragraph>

    <SocialLinks iconWidth="20px" />
  </Footer>
);

export default FooterComponent;
