// @flow
import React from 'react';

import { InView } from 'components';

import { Wrapper, Method } from './index.css';
import { convertHTMLtoStyled } from '../../../utils/convertHTMLtoStyled';

type Props = {
  columns: array[],
};

const Methods = ({ columns }: Props) => (
  <Wrapper>
    {columns.map(col => (
      <InView key={col.copy} fadeSlide>
        <Method>{convertHTMLtoStyled(col.copy, true)}</Method>
      </InView>
    ))}
  </Wrapper>
);

export default Methods;
