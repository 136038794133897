// @flow
import React from 'react';

import { Heading, Anchor } from 'components';
import * as Color from '../../../styles/colors';

import Facebook from '../../../../static/img/facebook.inline.svg';
import Instagram from '../../../../static/img/instagram.inline.svg';
import YouTube from '../../../../static/img/youtube.inline.svg';
import Vimeo from '../../../../static/img/vimeo.inline.svg';

import { useSocialData } from '../../../hooks/useSocialData';

import { Wrapper } from './index.css';

type Props = {
  iconColor: string,
  iconWidth: string,
  title: string,
};

const renderIcon = type => {
  switch (type) {
    case 'instagram':
      return <Instagram />;
    case 'facebook':
      return <Facebook />;
    case 'youtube':
      return <YouTube />;
    case 'vimeo':
      return <Vimeo />;
    default:
      return <div />;
  }
};

const SocialLinks = ({
  iconColor = Color.WHITE,
  iconWidth = 'auto',
  title,
}: Props) => {
  const data = useSocialData();

  return (
    <Wrapper iconColor={iconColor} iconWidth={iconWidth}>
      {title && <Heading.H4>{title}</Heading.H4>}
      {data.map(item => (
        <Anchor key={item.type} href={item.url}>
          {renderIcon(item.type)}
        </Anchor>
      ))}
    </Wrapper>
  );
};

export default SocialLinks;
