/* eslint-disable no-return-assign */
import React from 'react';
import convert from 'htmr';
import showdown from 'showdown';
import styled from 'styled-components';

import {
  Anchor,
  Paragraph,
  Heading,
  Button,
  TestimonialList,
  ProfileCard,
  MediaGallery,
  SocialLinks,
  InView,
  ContactForm,
  Timeline,
} from 'components';

import { Br, Strong, OrderList, UnorderList, ListItem } from '../styles/global';
import { TEAL_BLUE } from '../styles/colors';

const ButtonContainer = styled.div``;

// Removes <p /> from components.
const wrappedPsOfComp = {
  type: 'output',
  filter(text) {
    const re = /(<p><(span|a) data-noPWrap)(.+)(<\/(span|a)>)<\/p>/gm;
    return text.replace(re, '<$2$3$4');
  },
};

/**
 * Returns markdown code to HTML.
 * @param {string} md Markdown code.
 */
export const convertMDtoHTML = md => {
  const theConverter = new showdown.Converter({
    extensions: [wrappedPsOfComp],
    simpleLineBreaks: true,
  });
  return theConverter.makeHtml(md).replace(/\\<br \/>/gm, '<br />');
};

/**
 * Returns HTML as Styled components.
 * @param {string} str HTML or markdown code.
 * @param {boolean} isMd Is str markdown or HTML.
 */
export const convertHTMLtoStyled = (str, isMd = false) => {
  let i = 0;
  const html = isMd ? convertMDtoHTML(str) : str;
  const transform = {
    ol: OrderList,
    ul: UnorderList,
    li: ListItem,
    h3: Heading.H3,
    strong: Strong,
    _: (node, props, children) => {
      if (typeof props === 'undefined') {
        return node;
      }

      if (node === 'span') {
        const { className, type, limit, subject } = props;

        switch (className) {
          case 'testimonial':
            return <TestimonialList key={(i += 1)} id={children[0]} />;
          case 'profile-card':
            return <ProfileCard key={(i += 1)} type={type} />;
          case 'media-gallery':
            return <MediaGallery key={(i += 1)} limit={limit} />;
          case 'contact-form':
            return <ContactForm key={(i += 1)} subject={subject} />;
          case 'social-links':
            return (
              <SocialLinks
                key={(i += 1)}
                iconColor={TEAL_BLUE}
                title="Follow me on social"
              />
            );
          default:
            return node;
        }
      }

      if (node === 'p') {
        if (children[0] === '{{timeline}}') {
          return <Timeline key={(i += 1)} />;
        }
        return (
          <InView key={(i += 1)} fadeSlide>
            <Paragraph>{children}</Paragraph>
          </InView>
        );
      }

      if (node === 'a' && props.href) {
        const { href, className } = props;

        if (className === 'btn') {
          return (
            <InView key={(i += 1)} fadeSlide>
              <ButtonContainer>
                <Button href={href}>{children}</Button>
              </ButtonContainer>
            </InView>
          );
        }

        return (
          <Anchor key={(i += 1)} href={href}>
            {children}
          </Anchor>
        );
      }

      if (node === 'br') {
        return <Br key={(i += 1)} />;
      }

      return children;
    },
  };

  return convert(html, { transform });
};
