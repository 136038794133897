// @flow
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';

import { Paragraph, InView } from 'components';

import { useTimelineData } from '../../../hooks/useTimelineData';

import {
  Wrapper,
  DatesContainer,
  Date,
  Fill,
  EventsContainer,
  Event,
  EventContent,
  EventHeader,
} from './index.css';

const Timeline = () => {
  const data = useTimelineData();
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [activeDate, setActiveDate] = useState(data[0].date);
  const [dateIndex, setDateIndex] = useState(0);
  let timer;

  const handleDateClick = (date, index) => {
    setActiveDate(date);
    setDateIndex(index);
  };

  const isPastDate = date => {
    const currentDate = Number(activeDate);
    const dateParsed = Number(date);
    return dateParsed < currentDate;
  };

  const adjustTimelineWidth = (firstTime = false) => {
    const activeDateEl = document.querySelector(`[data-date="${activeDate}"]`);
    const { offsetLeft, offsetWidth } = activeDateEl;

    if (window.innerWidth < 900 || firstTime) {
      setTimelineWidth(`${offsetLeft + offsetWidth / 2}px`);
      cancelAnimationFrame(timer);
    }
  };

  useEffect(() => {
    if (activeDate) {
      adjustTimelineWidth(true);
    }

    window.addEventListener('resize', () => {
      timer = requestAnimationFrame(adjustTimelineWidth);
    });
  });

  return (
    <InView fadeSlide>
      <Wrapper>
        <DatesContainer>
          {data.map((item, index) => (
            <Date
              data-date={item.date}
              inactive={isPastDate(item.date)}
              active={activeDate === item.date}
              key={item.date}
              onClick={() => handleDateClick(item.date, index)}
            >
              {item.header}
            </Date>
          ))}
          <Fill width={timelineWidth} />
        </DatesContainer>
        <EventsContainer translate={100 * dateIndex}>
          {data.map(item => (
            <Event key={item.date}>
              <EventContent>
                <EventHeader>{item.header}</EventHeader>
                <Paragraph>{item.description}</Paragraph>
              </EventContent>
            </Event>
          ))}
        </EventsContainer>
      </Wrapper>
    </InView>
  );
};

export default Timeline;
