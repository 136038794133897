// @flow
import React from 'react';

import { Paragraph, Anchor, InView } from 'components';
import { useAboutData } from '../../../hooks/useAboutData';
import { renderUrl } from '../../../utils/renderCloudinaryUrl';
import { convertHTMLtoStyled } from '../../../utils/convertHTMLtoStyled';

import { BioCard, Bio } from './index.css';

type Props = {
  type: string,
};

const ProfileCard = ({ type }: Props) => {
  const { blurb, image, copy } = useAboutData();
  const isFull = type === 'full';

  return (
    <BioCard isFull={isFull}>
      <InView fadeSlide>
        <img
          alt="about me"
          data-src={renderUrl(image, { width: isFull ? 200 : 150 })}
        />
      </InView>
      <InView fadeSlide>
        <Bio>
          {!isFull ? (
            <>
              <Paragraph>{blurb}</Paragraph>
              <Anchor href="/about">Read More</Anchor>
            </>
          ) : (
            convertHTMLtoStyled(copy, true)
          )}
        </Bio>
      </InView>
    </BioCard>
  );
};

export default ProfileCard;
