import { useStaticQuery, graphql } from 'gatsby';

export const useTestimonialData = () => {
  const { allMarkdownRemark: { nodes } = [] } = useStaticQuery(graphql`
    query Testimonials {
      allMarkdownRemark(
        filter: { frontmatter: { quote: { regex: "/[a-z]/" } } }
      ) {
        nodes {
          frontmatter {
            id
            quote
            author
          }
        }
      }
    }
  `);

  return nodes.map(node => ({
    id: node.frontmatter.id,
    quote: node.frontmatter.quote,
    author: node.frontmatter.author,
  }));
};
