// @flow
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Navigation, NavLink, Hamburger, Line } from './index.css';

import { useToggle } from '../../../hooks/useToggle';

const sortLinks = (order, a, b) =>
  order.indexOf(a.frontmatter.title) - order.indexOf(b.frontmatter.title);

const PrimaryNavigation = () => {
  const {
    allMarkdownRemark: { nodes },
    markdownRemark: {
      frontmatter: { navOrder },
    },
  } = useStaticQuery(graphql`
    query NavQuery {
      markdownRemark(frontmatter: { keywords: { regex: "/[a-z]/" } }) {
        id
        frontmatter {
          navOrder
        }
      }

      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "page" } } }
      ) {
        nodes {
          id
          fields {
            slug
            slugNoTrail
          }
          frontmatter {
            title
            showInNav
          }
        }
      }
    }
  `);
  const [toggleState, setToggleState] = useToggle(false);
  return (
    <>
      <Hamburger active={toggleState} href="#" handleClick={setToggleState}>
        {[0, 1, 2].map(i => (
          <Line key={i} />
        ))}
      </Hamburger>
      <Navigation opened={toggleState}>
        {nodes
          .sort((a, b) => sortLinks(navOrder, a, b))
          .map(route => {
            const { id, frontmatter, fields } = route;
            const { title, showInNav } = frontmatter;

            if (showInNav) {
              return (
                <NavLink
                  key={id}
                  href={fields.slugNoTrail}
                  activeClassName="active"
                >
                  {title}
                </NavLink>
              );
            }

            return false;
          })}
      </Navigation>
    </>
  );
};

export default PrimaryNavigation;
