/* eslint-disable no-return-assign */
// @flow
import React from 'react';
import convert from 'htmr';

import { Button, Paragraph, InView, Anchor } from 'components';
import { Br, Strong } from '../../../styles/global';

import { Callout, Container } from './index.css';
import { convertMDtoHTML } from '../../../utils/convertHTMLtoStyled';

type Props = {
  theme: string,
  bgColor: string,
  message: string,
};

let i = 0;

const transform = {
  strong: Strong,
  br: Br,
  p: Paragraph,
  _: (node, props, children) => {
    if (typeof props === 'undefined') {
      return node;
    }

    if (node === 'a' && props.href) {
      const { href, className } = props;

      if (className === 'btn') {
        return (
          <Button key={(i += 1)} href={href}>
            {children}
          </Button>
        );
      }

      return (
        <Anchor key={(i += 1)} href={href}>
          {children}
        </Anchor>
      );
    }

    return node;
  },
};

const CalloutModule = ({
  theme = 'light',
  bgColor = '',
  message = 'Sign up for a meeting and a lesson today 👍',
}: Props) => {
  const messageHTML = convertMDtoHTML(message.replace(/\\n/g, ''));
  return (
    <Callout bgColor={bgColor} theme={theme}>
      <InView fadeSlide>
        <Container>{convert(messageHTML, { transform })}</Container>
      </InView>
    </Callout>
  );
};

export default CalloutModule;
