import styled, { css } from 'styled-components';

import { Anchor } from 'components';

import * as Color from '../../../styles/colors';
import { media } from '../../../styles/responsive';

const widthLine = '28px';
const heightIcon = 18;
const heightLine = 3;
const translateY = (heightIcon / 2);
const rotation = 45;

export const Navigation = styled.nav`
  grid-column: span 2;
  overflow: hidden;
  max-height: 0;
  transition: max-height .3s ease-in-out;
  font-size: 12px;

  ${media.md`
    max-height: 200px;
    margin-left: auto;
    grid-column: 2;
  `};

  ${media.lg`
    font-size: 14px;
  `};
`;

export const NavLink = styled(Anchor)`
  display: block;
  padding: 12px 20px;
  font-weight: 100;

  &.active {
    font-weight: 700;
  }

  &:after {
    display: none;
  }

  &:last-child {
    background-color: ${Color.TEAL_BLUE};
    color: ${Color.WHITE};

    ${media.md`
      padding: 30px 10px;
    `};

    ${media.lg`
      padding: 28px 20px;
    `};
  }

  ${media.md`
    display: inline-block;
    padding: 6px;
  `};
`;

export const Line = styled.div`
  width: 100%;
  height: ${heightLine}px;
  background-color: ${Color.TEAL_BLUE};
  position: absolute;
  left: 0;
  transition: transform 0.4s,
              opacity 0.4s;

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2) {
    top: 50%;
  }

  &:nth-child(3) {
    top: 100%;
  }
`;

export const Hamburger = styled(Anchor)`
  width: ${widthLine};
  height: ${heightIcon}px;
  position: relative;
  display: block;
  z-index: 2;
  margin: 18px 20px 0 auto;
  
  &:after {
    display: none;
  }

  ${props => props.active && css`
    & + ${Navigation} {
      max-height: 300px;
    }

    ${Line}:nth-child(1) {
      transform: translate(0, ${translateY}px) rotate(${rotation}deg);
    }

    ${Line}:nth-child(2) {
      opacity: 0;
    }

    ${Line}:nth-child(3) {
      transform: translate(0, ${translateY * -1}px) rotate(${rotation * -1}deg);
    }
  `};

  &:hover,
  &:focus {
    ${props => !props.active && css`
      & ${Line}:nth-child(1) {
        transform: translateY(${(heightLine / 2) * -1}px);
      }
      & ${Line}:nth-child(3) {
        transform: translateY(${heightLine / 2}px);
      }
    `};
      
  }

  ${media.md`display: none;`};
`;
