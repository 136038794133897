import styled from 'styled-components';

import { Heading } from 'components';
import * as Color from '../../../styles/colors';

import { Anchor } from '../../atoms/Anchor/index.css';

export const Wrapper = styled.div`
  border: 1px solid ${Color.TEAL_BLUE};
  padding: 10px;
  max-width: 200px;
  margin: 40px 0 0 0;

  svg {
    width: ${props => props.iconWidth};
  }

  ${Heading.H4} {
    margin-top: 0;
  }

  path {
    fill: ${props => props.iconColor};
  }

  ${Anchor} {
    display: inline-block;
    padding: 0 2px;

    &:after {
      display: none;
    }
  }
`;
