import styled, { createGlobalStyle, css, keyframes } from 'styled-components';

import { Button } from 'components';

import { BODY_FONT, HEADER_FONT } from './fonts';
import * as Color from './colors';

export const ButtonLight = css`
  border-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
`;

export const ButtonDark = css`
  border-color: ${Color.WHITE};
  color: ${Color.WHITE};
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeTransition = 'opacity .15s .25s ease-in-out';

export const Br = styled.br``;

export const Strong = styled.strong`font-weight: 600;`;

export const ListItem = styled.li`
  margin-bottom: 10px;
`;

export const UnorderList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;

  ${ListItem} {
    position: relative;
    padding-left: 20px;

    &:before {
      content: "•";
      color: ${Color.TEAL_BLUE};
      margin-right: 10px;
      font-size: 26px;
      position: absolute;
      line-height: 70%;
      left: 0;
      top: 0;
    }
  }
`;

export const OrderList = styled.ol`
  counter-reset: my-awesome-counter;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: left;

  ${ListItem} {
    counter-increment: my-awesome-counter;
    display: flex;
    align-items: center;

    &:before {
      ${HEADER_FONT};
      content: counter(my-awesome-counter);
      color: ${Color.TEAL_BLUE};
      font-size: 40px;
      font-style: italic;
      margin-right: 10px;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    -webkit-overflow-scrolling: touch;
    ${BODY_FONT}
    color: ${Color.BODY_COLOR};
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .js-inview {
    opacity: 0;
    transition: ${fadeTransition};

    &-loaded {
      opacity: 1;
    }
  }

  .fade-slide {
    opacity: 0;
    transform: translateX(-10px);
    transition: ${fadeTransition},
                transform .15s .25s ease-in-out;

    &.js-inview-loaded {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
`;

export const Section = styled.section`
  padding: 0 20px;
  padding-bottom: 40px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: ${props => props.bgColor || Color.WHITE};
  ${props => Color.DARK_COLORS.includes(props.bgColor) && css`
    color: ${Color.WHITE};

    ${Button} {
      ${ButtonDark}
    }
  `};

  ${props => props.bgColor === Color.PAPER && css`
    ${Button} {
      ${ButtonLight}
    }
  `};
`;
