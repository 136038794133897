// @flow
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Header, Footer } from 'components';

import { GlobalStyle } from '../../../styles/global';

import { Main } from './index.css';

type Props = {
  children: node,
};

const PageTemplate = ({ children }: Props) => {
  const {
    markdownRemark: {
      frontmatter: { title, keywords, description },
    },
  } = useStaticQuery(graphql`
    query SeoInfo {
      markdownRemark(frontmatter: { keywords: { regex: "/[a-z]/" } }) {
        id
        frontmatter {
          title
          keywords
          description
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:url" content="https://fgpianoschool.com/" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dseh89sdp/image/upload/v1581989084/fgapp_v0rfz3.png"
        />
      </Helmet>
      <GlobalStyle />
      <Header />
      <Main>{children}</Main>
      <Footer />
      <script defer async src="https://player.vimeo.com/api/player.js" />
    </>
  );
};

export default PageTemplate;
