// @flow
import React, { useLayoutEffect } from 'react';
import mediumZoom from 'medium-zoom';

import { InView, ImageContainer } from 'components';
import { Gallery } from './index.css';

import { useMediaData } from '../../../hooks/useMediaData';
import { renderUrl } from '../../../utils/renderCloudinaryUrl';

const IMAGE_TRANSFORMS = {
  aspect_ratio: '16:9',
  crop: 'fill',
};

type Props = {
  limit: number | null,
};

const MediaGallery = ({ limit = null }: Props) => {
  const data = useMediaData();
  const limited = Number(limit);
  const images = !isNaN(limited) ? data.slice(0, limited) : data;

  useLayoutEffect(() => {
    mediumZoom('[data-zoomable]', { margin: 0 });
  }, []);

  return (
    <Gallery>
      {images.map(image => (
        <InView key={image.id}>
          <ImageContainer>
            <InView>
              <img
                data-zoomable-2
                alt="media"
                data-zoom-src-2={renderUrl(image.url, IMAGE_TRANSFORMS)}
                data-src={renderUrl(image.url, {
                  ...IMAGE_TRANSFORMS,
                  width: 750,
                })}
              />
            </InView>
          </ImageContainer>
        </InView>
      ))}
    </Gallery>
  );
};

export default MediaGallery;
