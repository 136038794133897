import styled, { css } from 'styled-components';

import { Heading } from 'components';

import * as Color from '../../../styles/colors';
import { Section } from '../../../styles/global';
import { media } from '../../../styles/responsive';

export const HeroWrapper = styled(Section)`
  color: ${Color.WHITE};
  position: relative;
  min-height: 190px;
  align-items: center;
  background-color: #000;
  max-height: ${props => (props.video ? '500px' : 'none')};

  ${media.md`
    justify-content: flex-start;
    padding-top: 96px;
    text-align: left;
    min-height: 356px;
  `};

  ${props => props.video && css`
    padding: 56.25% 0 0 0 !important;
  `};

  ${props => !props.video && css`
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${Color.BODY_COLOR};
      opacity: .3;
    }
  `};

  ${props => props.bgImage && css`
    background: url(${props.bgImage}) top left no-repeat;
    background-size: cover;
    background-attachment: ${props.video ? 'local' : 'fixed'};
  `};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    height: 100%;
  }
`;

export const HeroTitle = styled(Heading.H1)`
  position: relative;
  z-index: 1;
  text-shadow: 1px 3px 2px rgba(0, 0, 0, .3);
`;

export const EndFrame = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .75);
  display: ${props => (props.show ? 'flex' : 'none')};
`;

export const PlayButton = styled.button`
  margin: auto;
  background-color: ${Color.BLUE_WHALE};
  border: 0;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, .3);
  text-indent: -9999em;
  padding: 3px 2px 19px 30px;
  outline: 0;

  &:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    border: 22px solid ${Color.WHITE};
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
`;
