import styled from 'styled-components';

import { media } from '../../../styles/responsive';

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px 0;

  ${media.md`
    grid-template-columns: repeat(3, 1fr);
  `};
`;
