import { useStaticQuery, graphql } from 'gatsby';

export const useMediaData = () => {
  const {
    allCloudinaryMedia: { nodes },
  } = useStaticQuery(graphql`
    query Cloudinary {
      allCloudinaryMedia(filter: { public_id: { regex: "/Media/" } }) {
        nodes {
          id
          secure_url
        }
      }
    }
  `);

  return nodes.map(node => ({
    id: node.id,
    url: node.secure_url.replace('q_auto,f_auto/', ''),
  }));
};
