const transformationMapping = {
  width: 'w',
  height: 'h',
  crop: 'c',
  aspect_ratio: 'ar',
  fetch_format: 'f',
  quality: 'q',
  background: 'b',
};

/**
 * Returns an image url with transforms.
 * @param {string} imageUrl The URL of the image.
 * @param {object} transforms Transforms to apply to image.
 */
export const renderUrl = (
  imageUrl,
  transforms = {
    fetch_format: 'auto',
  }
) => {
  const transformations = [];
  const properties = Object.keys(transforms);

  if (!imageUrl) {
    return imageUrl;
  }

  const [base, image] = imageUrl.split('upload/');

  properties.map(prop => {
    const property = transformationMapping[prop];
    const value = transforms[prop];
    return transformations.push(`${property}_${value}`);
  });

  return `${base}upload/${transformations.join(',')}/${image.replace(
    '.png',
    ''
  )}`;
};
