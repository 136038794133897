import styled, { css } from 'styled-components';

import { media } from '../../../styles/responsive';

export const Col = styled.div``;

export const Wrapper = styled.div`
  text-align: left;

  & > ${Col}:first-child {
    grid-column: 2;
  }
  
  ${media.md`
    display: grid;
    grid-template-columns: ${props => (props.isContact ? '1fr 1fr' : '2% 1fr 1fr 2%')};
    grid-column-gap: 5%;

    ${props => props.isContact && css`
      & > ${Col}:first-child {
        grid-column: 1;
      }
    `};
  `};

  ${media.lg`
    grid-template-columns: ${props => (props.isContact ? '1fr 1fr' : '10% 1fr 1fr 10%')};
  `};

  
`;
