import styled, { css } from 'styled-components';

import { Paragraph } from 'components';

import { media } from '../../../styles/responsive';

export const BioCard = styled.div`
  display: grid;
  text-align: ${props => (props.isFull ? 'left' : 'center')};

  ${media.md`
    grid-template-columns: 1fr 5fr;
    text-align: left;
    padding: ${props => (!props.isFull ? '0 20%' : '0')};
    grid-column-gap: 24px;
    align-items: ${props => (props.isFull ? 'flex-start' : 'center')};
    margin-top: 10px;
  `};

  ${props => props.isFull && css`
    ${media.lg`
      padding: 0 20%;
    `};
  `};  

  img {
    margin: 20px auto;
    display: block;

    ${media.md`
      margin: 0;
    `};
  }
`;

export const Bio = styled.div`
  ${Paragraph} {
    transition: none;
  }
`;
