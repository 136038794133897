import styled from 'styled-components';

import { Heading, Paragraph } from 'components';
import { media } from '../../../styles/responsive';
import { BODY_FONT } from '../../../styles/fonts';

export const Wrapper = styled.div`
  display: grid;
  margin: 20px 0 0 0;

  ${media.md`
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
  `};

  ${Heading.H3} {
    ${BODY_FONT};
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 0 0 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }
`;

export const Method = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, .1);

  ${Paragraph} {
    transition: none;
  }
`;
