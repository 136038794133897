import styled from 'styled-components';

import { Anchor } from 'components';

import * as Color from '../../../styles/colors';
import { media } from '../../../styles/responsive';

export const Header = styled.header`
  display: grid; 
  grid-template-columns: 1fr 4fr;
  position: fixed;
  top: 0;
  left: 0;
  background: ${Color.WHITE};
  z-index: 2;
  width: 100%;
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, .3);

  .medium-zoom--opened & {
    z-index: 0;
    opacity: 0;
  }
  
  ${media.md`
    align-items: center;
  `};
`;

export const LogoLink = styled(Anchor)`
  padding: 14px 20px;

  &:after {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 180px;

  ${media.md`
    width: 220px;
  `};
`;
