// @flow
import React from 'react';

import { InView } from 'components';

import { Wrapper, Quote, Author } from './index.css';

type Props = {
  quote: string,
  author: string,
  quoteFontSize: string,
  authorFontSize: string,
  quoteLineHeight: string,
  listItem: boolean,
};

const Blockquote = ({
  quote,
  author,
  quoteFontSize = '18px',
  authorFontSize = '14px',
  quoteLineHeight = '24px',
  listItem = false,
}: Props) => (
  <InView fadeSlide>
    <Wrapper isListItem={listItem}>
      <Quote lineHeight={quoteLineHeight} fontSize={quoteFontSize}>
        {quote}
      </Quote>
      <Author margin="0" fontSize={authorFontSize}>
        {author}
      </Author>
    </Wrapper>
  </InView>
);

export default Blockquote;
