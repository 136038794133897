import styled from 'styled-components';
import { HEADER_FONT } from '../../../styles/fonts';
import * as Color from '../../../styles/colors';
import { media } from '../../../styles/responsive';

export const Wrapper = styled.div`
  max-width: 900px;
  margin: 50px auto;
  overflow: hidden;
`;

export const DatesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;
  overflow-x: auto;

  ${media.md`
    margin-right: 60px;
    margin-left: 60px;
  `}

  &:after {
    content: '';
    position: absolute;
    height: 2px;
    background-color: ${Color.WHITE};
    left: 0;
    bottom: 8px;
    width: 100%;

    ${media.md`
      height: 4px;
      bottom: 9px;
    `}
  }
`;

export const EventsContainer = styled.div`
  display: flex;
  transform: translateX(-${props => props.translate}%);
  transition: all 250ms ease-in-out;
`;

export const Date = styled.button`
  text-align: center;
  text-decoration: none;
  color: #000;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  cursor: pointer;
  flex: 0 0 80px;

  ${media.md`
    flex: auto;
  `};

  &:focus {
    outline: none;
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${props => (props.active ? Color.TEAL_BLUE : Color.PAPER)};
    border-radius: 100%;
    display: block;
    margin: 15px auto 0;
    border-width: 3px;
    border-style: solid;
    border-color: ${props => (props.active || props.inactive ? Color.TEAL_BLUE : Color.WHITE)};
    position: relative;
    z-index: 2;
    transition: all 250ms ease-in-out;

    ${media.md`
      width: 15px;
      height: 15px;
      margin-top: 20px;
    `}
  }

  &:hover:after {
    background-color: ${Color.TEAL_BLUE};
    border-color: ${Color.TEAL_BLUE};
  }
`;

export const Event = styled.section`
  flex: 0 0 100%;
  position: relative;
  padding: 20px 0 0;
  text-align: left;
`;

export const EventContent = styled.div`
  ${media.md`
    padding: 0 90px;
  `}
`;

export const EventHeader = styled.p`
  ${HEADER_FONT};
  font-weight: 100;
  font-style: italic;
  line-height: normal;
  color: #000;
  margin: 10px 0;
  text-align: center;
  font-size: 30px;
`;

export const Fill = styled.div`
  background: ${Color.TEAL_BLUE};
  transition-duration: 250ms;
  position: absolute;
  left: 0px;
  bottom: 8px;
  width: ${props => props.width || 0};
  height: 2px;
  z-index: 1;

  ${media.md`
    height: 4px;
    bottom: 9px;
  `}
`;
