import styled, { css } from 'styled-components';

import { Paragraph } from 'components';

import * as Color from '../../../styles/colors';
import { HEADER_FONT, BODY_FONT } from '../../../styles/fonts';
import { media } from '../../../styles/responsive';

export const Quote = styled(Paragraph)`
  position: relative;
  font-style: italic;

  &:after {
    content: "„";
    right: 0;
    bottom: 25px;
  }    
`;

export const Wrapper = styled.blockquote`
  ${HEADER_FONT};
  padding: ${props => (props.isListItem ? '26px' : '8px')};
  margin: ${props => (props.isListItem ? '40px 0' : '0 0 20px 0')};
  position: relative;
  color: #666;
  text-align: left;
  ${props => props.isListItem && css`
    border: 2px solid ${Color.PAPER};
  `};

  ${media.md`
    ${props => (props.isListItem
      ? css`
        margin: 40px;
      ` : css`
        margin-right: 18%;
        margin-left: 18%;
    `)};
  `};

  &:before,
  ${Quote}:after {
    font-size: 90px;
    position: absolute;
    color: rgba(0, 0, 0, .2);
  }

  &:before {
    content: "“";
    left: ${props => (props.isListItem ? '10px' : '-10px')};
    top: ${props => (props.isListItem ? '-10px' : '-30px')};
  }
`;

export const Author = styled(Paragraph)`
  ${BODY_FONT};
`;
