export const TEAL_BLUE = '#063c62';
export const WHITE = '#fff';
export const PAPER = '#efefef';
export const BODY_COLOR = '#000';
export const BLUE_WHALE = '#053151';

export const DARK_COLORS = [
  TEAL_BLUE,
  BLUE_WHALE,
  BODY_COLOR,
];
