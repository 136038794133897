// @flow
import React from 'react';

import { InView } from 'components';

import { Wrapper, Col } from './index.css';
import { convertHTMLtoStyled } from '../../../utils/convertHTMLtoStyled';

type Props = {
  columns: array[],
  pageUrl: string,
};

const TwoColumns = ({ columns, pageUrl }: Props) => (
  <Wrapper isContact={pageUrl === 'Contact'}>
    {columns.map(col => (
      <InView key={col.copy} fadeSlide>
        <Col>{convertHTMLtoStyled(col.copy, true)}</Col>
      </InView>
    ))}
  </Wrapper>
);

export default TwoColumns;
