// @flow
import React, { useRef } from 'react';

import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';

// classes
const inview = 'js-inview';
const inviewLoaded = ` ${inview}-loaded`;
const fadeSlideCn = ' fade-slide';

type Props = {
  children: node,
  fadeSlide: boolean,
};

const InView = ({ children, fadeSlide = false }: Props) => {
  const ref = useRef();
  const isIntersecting = useIntersectionObserver(ref);

  const cloneProps = child => {
    const baseClass = `${
      child.type === 'img' && child.props['data-zoom-src-2']
        ? 'medium-zoom-image '
        : ''
    }${inview}${fadeSlide ? fadeSlideCn : ''}`;
    const theProps = {
      className: `${baseClass}${isIntersecting ? inviewLoaded : ''}`,
      ref,
    };

    if (child.type === 'img') {
      theProps.src = isIntersecting ? child.props['data-src'] : '';

      if (child.props['data-zoom-src-2']) {
        theProps['data-zoom-src'] = child.props['data-zoom-src-2'];
        theProps['data-zoomable'] = '';
      }
    }

    return theProps;
  };

  return (
    <>
      {React.Children.map(children, child =>
        React.cloneElement(child, cloneProps(child))
      )}
    </>
  );
};

export default InView;
